import { Box, Button, Text } from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Banner = (props) => {
  const navigate = useNavigate();

  const handleView = () => {
    navigate("/Assets");
  };

  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [props?.url, props?.content?.video?.url]);
  const bg_url = `${props?.url}${props?.content?.video?.url}`;

  return (
    <Box>
      <video
        autoPlay
        muted
        loop
        ref={videoRef}
        style={{
          position: "absolute",
          height: "1080px",
          width: "100%",
          objectFit: "cover",
          zIndex: "-1",
        }}
      >
        <source src={bg_url} type="video/mp4" />
      </video>
      <Box
        display={"flex"}
        flexDir={"column"}
        bg={"blackAlpha.100"}
        w={"100%"}
        height={"1080px"}
        objectFit={"cover"}
        zIndex={0}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text
          color={"white"}
          fontFamily={"Montserrat-Light"}
          fontSize={["28px", "56px"]}
          letterSpacing={-2}
        >
          {props?.content?.title}
        </Text>
        <Text
          letterSpacing={-1}
          color={"white"}
          fontFamily={"Montserrat-Semibold"}
        >
          {props?.content?.desc}
        </Text>
        <Button
          backgroundColor={"teal.300"}
          color={"white"}
          my={3}
          onClick={handleView}
        >
          VIEW ALL ART KITS
        </Button>
      </Box>
    </Box>
  );
};

export default Banner;
