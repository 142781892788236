import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const baseUrl = "https://api.polypixstudios.com";

export const assets = createAsyncThunk("users/Assets", async (thunkAPI) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/props?populate=image,links,fileFormats`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();

    if (response.status === 200) {
      return { data };
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const AssetsSlice = createSlice({
  name: "assets",
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [assets.pending]: (state) => {
      state.loading = true;
    },
    [assets.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [assets.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = AssetsSlice.actions;
export const assetSelector = (state) => state.assets;

// const props = [
//   {
//     id: 1,
//     image: require("../assets/props/1.png"),
//     name: "Rock Kit",
//     desc: "4x Rocks Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=5u4eg",
//       artStation:
//         "https://www.artstation.com/marketplace/p/XKBpp/rock-kit?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader: "https://www.cgtrader.com/3d-models/plant/other/rock-kit",
//     },
//   },
//   {
//     id: 2,
//     image: require("../assets/props/2.png"),
//     name: "Chandelier",
//     desc: "1x Chandelier Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=sctbmw",
//       artStation:
//         "https://www.artstation.com/marketplace/p/JrPWj/chandelier?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/house-interior/chandelier-54cc87bf-1b3f-42cd-a1f0-40f053a430a5",
//     },
//   },
//   {
//     id: 3,
//     image: require("../assets/props/3.png"),
//     name: "Damage Couch",
//     desc: "Damage Couch Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=ogtsxw",
//       artStation:
//         "https://www.artstation.com/marketplace/p/yrJ1G/damaged-couch?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/living-room/damaged-couch-88b3477e-8dca-4cef-84fd-455af90a4f37",
//     },
//   },
//   {
//     id: 4,
//     image: require("../assets/props/4.png"),
//     name: "Conifer Dead Tree Set",
//     desc: "3x Deadtrees Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=imuxg",
//       artStation:
//         "https://www.artstation.com/marketplace/p/aJ91q/dead-tree-kit?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/plant/conifer/dead-tree-set",
//     },
//   },
//   {
//     id: 5,
//     image: require("../assets/props/5.png"),
//     name: "Wooden Dinner Table",
//     desc: "1x Wooden Dinner Table Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/marketplace?q=polypix&product_id=sgrta",
//       artStation:
//         "https://www.artstation.com/marketplace/p/WryWK/wooden-dinner-table?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/living-room/wooden-dinner-table-cd5db027-5f35-458d-b94e-4d8e6ada2dfe",
//     },
//   },
//   {
//     id: 6,
//     image: require("../assets/props/6.png"),
//     name: "Abandoned Lake Boat",
//     desc: "x1 Abandoned Lake Boat x1 Paddle Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=cjj68a",
//       artStation:
//         "https://www.artstation.com/marketplace/p/K5P5N/abandoned-boat?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/vehicle/industrial-vehicle/abandoned-boat-1decde95-0a0a-414f-8c2c-250d2f0dc035",
//     },
//   },
//   {
//     id: 7,
//     image: require("../assets/props/7.png"),
//     name: "Spooky Frame",
//     desc: "x1 Spooky Bread Monster Frame Game-read",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=xiwr8w",
//       artStation:
//         "https://www.artstation.com/marketplace/p/AaPpj/bread-monster-picture-frame?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/interior-office/horror-picture-frame",
//     },
//   },
//   {
//     id: 8,
//     image: require("../assets/props/8.png"),
//     name: "Rocking Chair",
//     desc: "x1 Rocking Chair Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=9bcayw",
//       artStation:
//         "https://www.artstation.com/marketplace/p/LwPxG/rocking-chair?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/living-room/rocking-chair-a8b862a4-7cec-47f6-836a-b984da7d359d",
//     },
//   },
//   {
//     id: 9,
//     image: require("../assets/props/9.png"),
//     name: "Side Table",
//     desc: "1x Sidetable Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=olgpfq",
//       artStation:
//         "https://www.artstation.com/marketplace/p/K5MRA/side-table?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/bedroom/sidetable-265d2b15-6e88-4da0-a50e-24a51b5f9659",
//     },
//   },
//   {
//     id: 10,
//     image: require("../assets/props/10.png"),
//     name: "Cap Stand",
//     desc: "Cap Stand Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=b3wtw",
//       artStation:
//         "https://www.artstation.com/marketplace/p/k10xA/cap-stand?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/living-room/cap-stand",
//     },
//   },
//   {
//     id: 11,
//     image: require("../assets/props/11.png"),
//     name: "Wardrobe",
//     desc: "1x Wardrobe Game-ready",
//     fileFormat: ["FBX"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=yeaga",
//       artStation:
//         "https://www.artstation.com/marketplace/p/pkmd6/wardrobe?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/bedroom/wardrobe-c65af23e-d4ec-4e7a-82be-ac765afa9847",
//     },
//   },
//   {
//     id: 12,
//     image: require("../assets/props/12.png"),
//     name: "Lying Angel Tombstone",
//     desc: "1x Lying Angel Tombstone Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=p3xbqa",
//       artStation:
//         "https://www.artstation.com/marketplace/p/yrJKe/lying-statue-angel-tomb-stone?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/exterior/historic-exterior/lying-statue",
//     },
//   },
//   {
//     id: 13,
//     image: require("../assets/props/13.png"),
//     name: "Bookshelf",
//     desc: "x1 Bookshelf Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=wsqvha",
//       artStation:
//         "https://www.artstation.com/marketplace/p/3NPNL/bookshelf?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/interior-office/book-shelf-2b18e9b7-aa39-4ddd-9c55-5872933e0cad",
//     },
//   },
//   {
//     id: 14,
//     image: require("../assets/props/14.png"),
//     name: "Mailbox",
//     desc: "1x Mailbox Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=klr4q",
//       artStation:
//         "https://www.artstation.com/marketplace/p/6YPL3/house-mailbox?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/exterior/house/mailbox-43df0459-9476-4147-90f0-d7913c7f3950",
//     },
//   },
//   {
//     id: 15,
//     image: require("../assets/props/15.png"),
//     name: "Cabinet",
//     desc: "x1 Cabinet Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=fpca",
//       artStation:
//         "https://www.artstation.com/marketplace/p/wb1br/cabinet?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/kitchen/cabinet-ca48aca3-0aa0-4789-a72c-3d9887ab6e74",
//     },
//   },
//   {
//     id: 16,
//     image: require("../assets/props/16.png"),
//     name: "Spooky Metal Mirror",
//     desc: "Spooky Metal Mirror Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=tfaua",
//       artStation:
//         "https://www.artstation.com/marketplace/p/edRaK/spooky-mirror?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/bathroom/spooky-mirror",
//     },
//   },
//   {
//     id: 17,
//     image: require("../assets/props/17.png"),
//     name: "Old Abandoned Bed",
//     desc: "x1 Old Abandoned Bed Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=ebeakg",
//       artStation:
//         "https://www.artstation.com/marketplace/p/9aPab/old-abandoned-bed?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/bedroom/old-abandoned-bed",
//     },
//   },
//   {
//     id: 18,
//     image: require("../assets/props/18.png"),
//     name: "Jack O Lantern",
//     desc: "x1 Jack O Lantern Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=ngikcg",
//       artStation:
//         "https://www.artstation.com/marketplace/p/Dnql2/jack-o-latern-kit?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/exterior/house/haloween-pumkins",
//     },
//   },
//   {
//     id: 19,
//     image: require("../assets/props/19.png"),
//     name: "Candelabra",
//     desc: "1X Candelabra Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=ywhltq",
//       artStation:
//         "https://www.artstation.com/marketplace/p/lbGbB/candelabra?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/kitchen/candelabra-46df2828-dc43-4a1e-9a09-c1e6608a2290",
//     },
//   },
//   {
//     id: 20,
//     image: require("../assets/props/20.png"),
//     name: "Piano",
//     desc: "x1 Piano Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=foijq",
//       artStation:
//         "https://www.artstation.com/marketplace/p/grLVO/piano?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/hall/classic-piano-174dbfb7-6df2-4103-9745-8fc49255a09b",
//     },
//   },
//   {
//     id: 21,
//     image: require("../assets/props/21.png"),
//     name: "Log Rack",
//     desc: "x1 Log Rack Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=ibiga",
//       artStation:
//         "https://www.artstation.com/marketplace/p/BnaKz/firewood-log-rack?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/exterior/industrial-exterior/log-rack",
//     },
//   },
//   {
//     id: 22,
//     image: require("../assets/props/22.png"),
//     name: "Skull",
//     desc: "1x Skull Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=wqbnpw",
//       artStation:
//         "https://www.artstation.com/marketplace/p/3NoKk/skull?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/science/medical/skull-e3a545a1-ff58-43a4-a369-fe7c8e5593a0",
//     },
//   },
//   {
//     id: 23,
//     image: require("../assets/props/23.png"),
//     name: "Tombstone Set",
//     desc: "3x Tombstone Game-ready",
//     fileFormat: ["FBX"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=z2mrea",
//       artStation:
//         "ttps://www.artstation.com/marketplace/p/8gJmP/tombstone-kit?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/exterior/historic-exterior/tombstone-d116d9a3-fcbe-424a-8ac9-e243a4c20543",
//     },
//   },
//   {
//     id: 24,
//     image: require("../assets/props/24.png"),
//     name: "Wooden Recliner Chair",
//     desc: "1x Wooden Recliner Chair Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=evrz5g ",
//       artStation:
//         "https://www.artstation.com/marketplace/p/dNwGq/wooden-recliner-chair?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/living-room/recliner-wood-chair",
//     },
//   },
//   {
//     id: 25,
//     image: require("../assets/props/25.png"),
//     name: "Purgatory Angel Statue",
//     desc: "x1 Purgatory Angel Statue Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=ggqmvq",
//       artStation:
//         "https://www.artstation.com/marketplace/p/XKBKL/angel-tombstone-statue?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/furniture/outdoor-furniture/angel-statue-6e71ad13-0ad4-49a2-bc94-f4d5ece007ca",
//     },
//   },
//   {
//     id: 26,
//     image: require("../assets/props/26.png"),
//     name: "Buckingham Chair",
//     desc: "Buckingham Chair Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=s6j8fw",
//       artStation:
//         "https://www.artstation.com/marketplace/p/Wrk31/birmingham-chair?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/living-room/vintage-chair-8b678970-0a91-408c-b9c4-19da3cd5962f",
//     },
//   },
//   {
//     id: 27,
//     image: require("../assets/props/27.png"),
//     name: "Deer Head",
//     desc: "x1 Deer Head Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=s2i8vg",
//       artStation: "https://www.artstation.com/marketplace/p/6YPlK/deer-head?",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/animals/mammal/deer-head-b5a39380-1de3-4ba8-a747-a892811f95ce",
//     },
//   },
//   {
//     id: 28,
//     image: require("../assets/props/28.png"),
//     name: "Fireplace",
//     desc: "x1 Fireplace Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=qbgjeg",
//       artStation:
//         "https://www.artstation.com/marketplace/p/edRrx/fireplace?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/house-interior/fireplace-9bb2ac5c-46de-46d4-b781-445ffe61d630",
//     },
//   },
//   {
//     id: 29,
//     image: require("../assets/props/29.png"),
//     name: "Candle Kit",
//     desc: "3x Candle Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=s1xgvq",
//       artStation:
//         "https://www.artstation.com/marketplace/p/pkgkk/candle-kit?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/exterior/historic-exterior/candles-032031da-a7b0-418c-b7d3-9e9bac949960",
//     },
//   },
//   {
//     id: 30,
//     image: require("../assets/props/30.png"),
//     name: "Grandfather Clock",
//     desc: "x1 Grandfather Clock Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=f1hxbq",
//       artStation:
//         "https://www.artstation.com/marketplace/p/1LP3z/grandfather-clock?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/interior/living-room/grandfather-clock-13eee206-35d6-4d4c-8721-68359d84303b",
//     },
//   },
//   {
//     id: 31,
//     image: require("../assets/props/31.png"),
//     name: "Agony Angel Statue",
//     desc: "x1 Agony Angel Statue Game-ready",
//     fileFormat: ["FBX", "OBJ"],
//     links: {
//       cubeBrush: "https://cubebrush.co/polypixstudios?product_id=kp4ga",
//       artStation:
//         "https://www.artstation.com/marketplace/p/OyPy9/agony-angel-tombstone-statue?utm_source=artstation&utm_medium=referral&utm_campaign=homepage&utm_term=marketplace",
//       cgTrader:
//         "https://www.cgtrader.com/3d-models/furniture/outdoor-furniture/agony-angel-statue",
//     },
//   },
// ];
// export default props;
