import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const SectionC = (props) => {
  const bg_url = `${props?.url}${props?.content?.image?.url}`;
  return (
    <Box
      height={"960px"}
      display={"flex"}
      flexDir={["column", "row"]}
      justifyContent={"stretch"}
    >
      <Box
        color={"white"}
        backgroundColor={"black"}
        fontFamily={"montserrat"}
        w={["100%", "50%"]} //Added another option (100%) for mobile devices
        h={"100%"}
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Box
          flexDir={"column"}
          display={"flex"}
          textAlign={["center", "left"]} //Added center alignment for mobile devices
          width={["100%"]}
          px={[0, 0, 20, 40, 40]}
        >
          <Text
            fontSize={["23px", "23px", "35px", "42px", "42px"]}
            letterSpacing={-2}
            fontFamily={"Montserrat-Bold"}
            width={["100%", "100%"]}
          >
            {props?.content?.title}
          </Text>
          <Text
            color={"whiteAlpha.700"}
            fontFamily={"Montserrat-Light"}
            letterSpacing={-1}
            textShadow={"0 0 1px white"}
            fontSize={["13px", "13px", "18px", "20px", "20px"]}
            width={["100%", "100%"]}
          >
            {props?.content?.desc}
          </Text>
        </Box>
      </Box>
      <Box w={["100%", "50%"]} h={"100%"} backgroundColor={"black"}>
        <Image src={bg_url} w={["100%", "100%"]} h={"100%"} objectFit={"cover"} />
      </Box>
    </Box>
  );
};

export default SectionC;
