import { Box, Image, Text } from "@chakra-ui/react";
import Logo from "./Assets/logo.png";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiMenu, HiX } from "react-icons/hi";

const Navbar = () => {
  const [show, setShow] = useState(false);

  const handleMenu = () => {
    setShow(true);
    // setInterval(() => {
    //   setShow(false);
    // }, 3000);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Box
        display={"flex"}
        flexDir={"row"}
        justifyContent={["center", "space-between"]}
        py={5}
        px={[0, 10]}
        position={"fixed"}
        zIndex={10}
      >
        <Box display={["flex", "none"]} alignItems={"center"} ml={3}>
          {!show ? (
            <HiMenu
              color="white"
              w={"100%"}
              onClick={handleMenu}
              cursor={"pointer"}
            />
          ) : (
            <HiX
              color="white"
              w={"100%"}
              onClick={handleClose}
              cursor={"pointer"}
            />
          )}
        </Box>
        <Box px={5} display={"flex"} justifyContent={["center", "flex-start"]}>
          <Image src={Logo} w={["16%", "7%"]} />
        </Box>
        <Box
          color={"white"}
          w={"100%"}
          display={["none", "flex"]}
          justifyContent={"flex-end"}
          alignItems={"center"}
          fontFamily={"Montserrat-Bold"}
          letterSpacing={-1}
        >
          <Link to={"/"}>
            <Text mx={5}>Home</Text>
          </Link>
          <Link to={"Assets"}>
            <Text mx={5}>Assets</Text>
          </Link>
          <Link to={"ContactUs"}>
            <Text mx={5}>Contact Us</Text>
          </Link>
        </Box>
      </Box>
      {show === true ? (
        <Box
          display={"flex"}
          flexDir={"column"}
          color={"white"}
          px={3}
          position={"fixed"}
          w={"100%"}
          top={20}
          zIndex={10}
        >
          <Link to={"/"}>
            <Text my={2}>Home</Text>
          </Link>
          <Link to={"Assets"}>
            <Text my={2}>Assets</Text>
          </Link>
          <Link to={"ContactUs"}>
            <Text my={2}>Contact Us</Text>
          </Link>
        </Box>
      ) : null}
    </>
  );
};

export default Navbar;
