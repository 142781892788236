import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const baseUrl = "https://api.polypixstudios.com";

export const assetPacks = createAsyncThunk(
  "users/AssetPacks",
  async (thunkAPI) => {
    try {
      const response = await fetch(`${baseUrl}/api/packs/?populate=image`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const AssetPacksSlice = createSlice({
  name: "packs",
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [assetPacks.pending]: (state) => {
      state.loading = true;
    },
    [assetPacks.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [assetPacks.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = AssetPacksSlice.actions;
export const assetPacksSelector = (state) => state;

// const data = [
//   {
//     id: 1,
//     image: require("../assets/packs/Pack Slider_01.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/stylized-farm",
//   },
//   {
//     id: 2,
//     image: require("../assets/packs/Pack Slider_02.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/stylized-laundry-shop",
//   },
//   {
//     id: 3,
//     image: require("../assets/packs/Pack Slider_03.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/stylized-diner",
//   },
//   {
//     id: 4,
//     image: require("../assets/packs/Pack Slider_04.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/slum-island",
//   },
//   {
//     id: 5,
//     image: require("../assets/packs/Pack Slider_05.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/stylized-asia-town",
//   },
//   {
//     id: 6,
//     image: require("../assets/packs/Pack Slider_06.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/apocalyptic-weapons",
//   },
//   {
//     id: 7,
//     image: require("../assets/packs/Pack Slider_07.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/horror-sewer-environment",
//   },
//   {
//     id: 8,
//     image: require("../assets/packs/Pack Slider_08.png"),
//     link: "https://www.unrealengine.com/marketplace/en-US/product/d6a04977016443759c6610b97c89eb0b",
//   },
// ];
// export default data;
