/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Banner from "./components/banner";
import SectionA from "./components/sectionA";
import SectionB from "./components/sectionB";
import SectionC from "./components/sectionC";
import SectionD from "./components/sectionD";
import SectionE from "./components/sectionE";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contentSelector, content } from "./actions/data";

const Homepage = () => {
  const dispatch = useDispatch();
  const baseUrl = "https://api.polypixstudios.com";
  const landing_content = useSelector(contentSelector);
  const pack = landing_content?.content?.data?.content;
  console.log(pack);
  useEffect((data) => {
    dispatch(content({ data: data }));
  }, []);
  return (
    <>
      <Banner content={pack?.banner} url={baseUrl} />
      <SectionA content={pack?.about} />
      <SectionB content={pack?.sections[0]} url={baseUrl} />
      <SectionC content={pack?.sections[1]} url={baseUrl} />
      <SectionD content={pack?.sections[2]} url={baseUrl} />
      <SectionE />
    </>
  );
};

export default Homepage;
