import React from "react";
import AssetPacks from "./components/assetPacks";
import Assets from "./components/banner";
import Materials from "./components/materials";

import { Box, Button } from "@chakra-ui/react";

const AssetPage = () => {
  return (
    <>
      <Box
        position={"fixed"}
        width={"10%"}
        height={"100%"}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
        right={10}
        zIndex={5}
      >
        <Box
          display={["none", "none", "none", "flex", "flex", "flex"]}
          flexDir={"column"}
          gap={5}
          justifyContent={"center"}
          borderColor={"white"}
          borderWidth={"1px"}
          p={5}
          borderRadius={5}
          backgroundColor={"blackAlpha.500"}
        >
          <Button
            _selection={{ backgroundColor: "none" }}
            _hover={{
              backgroundColor: "none",
              textDecocation: "underline",
              fontSize: "28px",
              transitionDuration: "10s",
            }}
            display={"flex"}
            justifyContent={"start"}
            fontFamily={"Montserrat-Light"}
            width={"100%"}
            fontSize={"25px"}
            textAlign={"start"}
            variant={"unstyled"}
            color={"white"}
            transition={"width .35s ease-in-out"}
            onClick={() => {
              document
                .getElementById("Store")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            Store Packs
          </Button>

          <Button
            _hover={{
              backgroundColor: "none",
              textDecocation: "underline",
              fontSize: "28px",
              transitionDuration: "10s",
            }}
            display={"flex"}
            justifyContent={"start"}
            fontFamily={"Montserrat-Light"}
            width={"100%"}
            fontSize={"25px"}
            textAlign={"start"}
            variant={"unstyled"}
            color={"white"}
            onClick={() => {
              document
                .getElementById("Asset")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            Assets
          </Button>
          <Button
            _selection={{ backgroundColor: "none" }}
            _hover={{
              backgroundColor: "none",
              textDecocation: "underline",
              fontSize: "28px",
              transitionDuration: "10s",
            }}
            display={"flex"}
            justifyContent={"start"}
            fontFamily={"Montserrat-Light"}
            width={"100%"}
            fontSize={"25px"}
            textAlign={"start"}
            variant={"unstyled"}
            color={"white"}
            onClick={() => {
              document
                .getElementById("Materials")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            Materials
          </Button>
        </Box>
      </Box>
      <Box id={"Store"}>
        <AssetPacks />
      </Box>
      <Box id={"Asset"}>
        <Assets />
      </Box>
      <Box id={"Materials"}>
        <Materials />
      </Box>
    </>
  );
};

export default AssetPage;
