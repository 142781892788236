import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const data = [
  {
    id: 1,
    image: require("../assets/artstation.png"),
    link: "https://www.artstation.com/polypixcc/store",
  },
  {
    id: 2,
    image: require("../assets/cgtrader.png"),
    link: "https://www.cgtrader.com/polypix-creatves",
  },
  {
    id: 3,
    image: require("../assets/turbosquid.png"),
    link: "https://www.turbosquid.com/Search/Artists/Polypix-Studios",
  },
  {
    id: 4,
    image: require("../assets/cubebrush.png"),
    link: "https://cubebrush.co/polypixstudios",
  },
  {
    id: 5,
    image: require("../assets/unreal.png"),
    link: "https://www.unrealengine.com/marketplace/en-US/profile/Polypix+Studios?count=20&sortBy=effectiveDate&sortDir=DESC&start=0",
  },
];
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 50,
    slidesToSlide: 1,
  },
};
const SectionE = () => {
  return (
    <Box
      backgroundColor={"black"}
      display={"flex"}
      flexDir={"column"}
      alignContent={"center"}
      textAlign={"center"}
      py={10}
    >
      <Text
        color={"white"}
        fontFamily={"Montserrat-Light"}
        fontSize={"42px"}
        letterSpacing={-2}
      >
        SHOP
      </Text>
      <Carousel
        responsive={responsive}
        infinite
        centerMode
        autoPlay
        autoPlaySpeed={2000}
        arrows={false}
      >
        {data.map((item) => {
          return (
            <Box
              display={"flex"}
              alignItems={"center"}
              alignContent={"center"}
              justifyContent={"center"}
              height={"100%"}
              py={10}
              key={item.id}
            >
              <a
                as={"button"}
                rel={"noreferrer"}
                target={"_blank"}
                href={item.link}
                replace
              >
                <Image
                  src={item.image}
                  w={["100px", "150px"]}
                  height={"fit-content"}
                  _hover={{
                    width: ["120px", "180px"],
                    transitionDuration: ".2s",
                  }}
                />
              </a>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default SectionE;
