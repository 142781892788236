import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  Image,
} from "@chakra-ui/react";

const ViewAsset = ({ isModalVisible, handleView, data }) => {
  return (
    <>
      <Modal
        isCentered
        onClose={handleView}
        isOpen={isModalVisible}
        motionPreset="slideInBottom"
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <Box display={"flex"} flexDir={["column", "row"]}>
              <Box width={["100%", "100%", "100%", "60%"]}>
                <Image
                  src={`https://api.polypixstudios.com${data?.attributes?.image.data[0].attributes.url}`}
                  h={"100%"}
                  w={"100%"}
                />
              </Box>
              <Box
                p={10}
                backgroundColor={"black"}
                color={"white"}
                fontFamily={"montserrat"}
                width={["100%", "100%", "100%", "40%"]}
                display={"flex"}
                justifyContent={"Start"}
              >
                <Box flexDir={"column"} display={"flex"} width={"100%"} px={5}>
                  <Text
                    fontSize={["28px", "42px"]}
                    letterSpacing={-2}
                    fontFamily={"Montserrat-Bold"}
                  >
                    {data?.attributes?.name}
                  </Text>
                  <Text
                    color={"whiteAlpha.300"}
                    fontFamily={"Montserrat-Light"}
                    letterSpacing={0}
                    fontSize={"18px"}
                    textShadow={"0 0 1px white"}
                  >
                    {data?.attributes?.desc}
                  </Text>
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDir={"column"}
                    width={"100%"}
                    my={5}
                  >
                    <Text fontSize={"24px"}>FILE FORMATS :</Text>
                    <Box
                      display={"flex"}
                      color={"whiteAlpha.700"}
                      fontFamily={"Montserrat-Light"}
                      letterSpacing={0}
                      textShadow={"0 0 1px white"}
                      my={2}
                    >
                      <Box display={"flex"}>
                        {data.attributes?.fileFormats?.map((f) => {
                          return (
                            <Text
                              mr={2}
                              backgroundColor={"blackAlpha.800"}
                              borderRadius={20}
                              fontSize={"15px"}
                              fontWeight={"bold"}
                              color={"white"}
                            >
                              {f}
                            </Text>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    flexDir={"column"}
                    width={"100%"}
                  >
                    <Text fontSize={"24px"}>AVAILABLE STORES :</Text>
                    <Box
                      display={"flex"}
                      color={"whiteAlpha.700"}
                      width={["100%", "100%", "100%", "50%"]}
                      alignItems={"center"}
                      gap={5}
                      my={3}
                    >
                      <a
                        as={"button"}
                        rel={"noreferrer"}
                        target={"_blank"}
                        href={data.attributes?.links?.cubeBrush}
                        replace
                      >
                        <Image
                          objectFit={"cover"}
                          src={require("../assets/cubebrush.png")}
                          w={"100%"}
                        />
                      </a>
                      <a
                        as={"button"}
                        rel={"noreferrer"}
                        target={"_blank"}
                        href={data.attributes?.links?.artStation}
                        replace
                      >
                        <Image
                          objectFit={"cover"}
                          src={require("../assets/artstation.png")}
                          w={"100%"}
                        />
                      </a>
                      <a
                        as={"button"}
                        rel={"noreferrer"}
                        target={"_blank"}
                        href={data.attributes?.links?.cgTrader}
                        replace
                      >
                        <Image
                          objectFit={"cover"}
                          src={require("../assets/cgtrader.png")}
                          w={"100%"}
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewAsset;
