import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";

const ViewTexture = ({ isModalVisible, handleView, data }) => {
  return (
    <>
      <Modal
        isCentered
        onClose={handleView}
        isOpen={isModalVisible}
        motionPreset="slideInBottom"
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={0}>
            <Box
              backgroundImage={`https://api.polypixstudios.com${data?.attributes?.preview.data.attributes.url}`}
              backgroundRepeat={"no-repeat"}
              backgroundSize={"cover"}
            >
              <Box
                p={10}
                bgGradient={"linear(to-l, blackAlpha.800, whiteAlpha.300,)"}
                color={"white"}
                fontFamily={"montserrat"}
                w={"100%"}
                h={"100%"}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Box
                  flexDir={"column"}
                  display={"flex"}
                  textAlign={"right"}
                  width={"100%"}
                  px={5}
                >
                  <Text
                    fontSize={["28px", "42px"]}
                    letterSpacing={-2}
                    fontFamily={"Montserrat-Bold"}
                  >
                    {data.attributes?.name}
                  </Text>
                  <Text
                    color={"whiteAlpha.700"}
                    fontFamily={"Montserrat-Light"}
                    letterSpacing={0}
                    fontSize={"12px"}
                    textShadow={"0 0 1px white"}
                    pl={20}
                  >
                    {data.attributes?.desc}
                  </Text>
                  <Button
                    variant={"outline"}
                    borderColor={"teal.300"}
                    my={5}
                    color={"teal.300"}
                  >
                    BUY NOW
                  </Button>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewTexture;
