import { configureStore } from "@reduxjs/toolkit";
import { AssetsSlice } from "../GameAssets/actions/assets";
import { AssetPacksSlice } from "../GameAssets/actions/data";
import { MaterialSlice } from "../GameAssets/actions/materials";
import { contentSlice } from "../Home/actions/data";
export default configureStore({
  reducer: {
    assetPacks: AssetPacksSlice.reducer,
    assets: AssetsSlice.reducer,
    materials: MaterialSlice.reducer,
    content: contentSlice.reducer,
  },
});
