import React, { useEffect, useState } from "react";
import { Box, Image, Text, Grid, GridItem } from "@chakra-ui/react";
import ViewTexture from "../modal/viewTexture";
import { useDispatch, useSelector } from "react-redux";
import { materials as texture, materialSelector } from "../actions/materials";
const Materials = () => {
  const dispatch = useDispatch();
  const materials = useSelector(materialSelector);
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const handleShow = () => {
    setShow(true);
  };
  useEffect((data) => {
    dispatch(texture({ data: data }));
  }, []);
  return (
    <>
      <ViewTexture
        isModalVisible={show}
        handleView={() => setShow(false)}
        data={selectedValue ?? []}
      />
      <Box
        backgroundColor={"black"}
        display={"flex"}
        flexDir={"column"}
        alignContent={"center"}
        textAlign={"center"}
        py={10}
      >
        <Text
          color={"white"}
          fontFamily={"Montserrat-Light"}
          fontSize={["32px", "42px"]}
          letterSpacing={-2}
        >
          MATERIALS
        </Text>
        <Grid
          templateColumns={["repeat(3, 1fr)", "repeat(5, 1fr)"]}
          py={10}
          gap={2}
        >
          {materials?.data?.data?.map((item) => {
            return (
              <GridItem w={["100%", "100%"]}>
                <Box key={item.id}>
                  <Image
                    src={`https://api.polypixstudios.com${item.attributes.image.data.attributes.url}`}
                    onClick={() => {
                      handleShow();
                      setSelectedValue(item);
                    }}
                  />
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default Materials;
