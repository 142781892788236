import { Box, Image, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { assetPacksSelector, assetPacks } from "../actions/data";

const AssetPacks = () => {
  const dispatch = useDispatch();
  const assets = useSelector(assetPacksSelector);
  const pack = assets?.assetPacks?.data?.data;

  useEffect((data) => {
    dispatch(assetPacks({ data: data }));
  }, []);
  return (
    <Box
      backgroundColor={"black"}
      display={"flex"}
      flexDir={"column"}
      alignContent={"center"}
      textAlign={"center"}
      py={10}
      zIndex={10}
    >
      <Text
        mt={20}
        color={"white"}
        fontFamily={"Montserrat-Light"}
        fontSize={["32px", "42px"]}
        letterSpacing={-2}
      >
        STORE PACKS
      </Text>
      <Marquee loop={0} gradient={false} speed={[100]} pauseOnHover={true}>
        {pack?.map((item) => {
          return (
            <Box
              display={"flex"}
              alignItems={"center"}
              alignContent={"center"}
              justifyContent={"center"}
              height={"100%"}
              py={10}
              mx={2}
              key={item.id}
            >
              <a
                as={"button"}
                rel={"noreferrer"}
                target={"_blank"}
                href={item.attributes.link}
                replace={item.attributes.link.toString()}
              >
                <Image
                  src={`https://api.polypixstudios.com${item.attributes.image?.data?.attributes?.url}`}
                  w={["200px", "200px", "200px", "400px"]}
                  objectFit={"cover"}
                />
              </a>
            </Box>
          );
        })}
      </Marquee>
    </Box>
  );
};

export default AssetPacks;
