import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const baseUrl = "https://api.polypixstudios.com";

export const content = createAsyncThunk("users/content", async (thunkAPI) => {
  try {
    const response = await fetch(`${baseUrl}/api/landing/content`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    let data = await response.json();
    if (response.status === 200) {
      return { data };
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const contentSlice = createSlice({
  name: "content",
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [content.pending]: (state) => {
      state.loading = true;
    },
    [content.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [content.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = contentSlice.actions;
export const contentSelector = (state) => state;
