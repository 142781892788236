import api from "../actions/api";
const baseUrl = "https://api.polypixstudios.com";
const contactUs = (params, callback = null) => {
  api
    .post(`${baseUrl}/api/applications`, params)
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err) {
        if (err) {
          callback(err.message);
        }
      }
    });
};
export { contactUs };
