import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";

import { contactUs } from "../actions/contactSlice";
const Banner = () => {
  const toast = useToast();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState("");
  const hiddenFileInput = useRef(null);

  const handleUpload = (e) => {
    hiddenFileInput.current.click();
  };
  const handleFileSelect = (event) => {
    setFile(event.target.files[0]);
  };

  const callback = async (res) => {
    const { error, status } = await res;
    if (res) {
      if (status === 200) {
        toast({
          title: "Resume uploaded successfully.",
          status: `success`,
          position: "top",
          isClosable: true,
          variant: "top-accent",
        });
        // navigate(`/client/OrderOfPayment`);
      } else {
        toast({
          title: res,
          status: `error`,
          position: "top",
          isClosable: true,
          variant: "top-accent",
        });
      }
    }
  };

  const handleSubmit = () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };
    const formData = new FormData();
    formData.append("files.resume", file);
    formData.append("data", JSON.stringify(data));
    contactUs(formData, callback);
  };

  return (
    <Box height="calc(100vh)" w={"100%"} display={"flex"}>
      <Box
        display={"flex"}
        flexDir={"column"}
        w={"100%"}
        h={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
        bg={"black"}
        alignContent={"center"}
        py={20}
      >
        <Text
          color={"white"}
          fontFamily={"Montserrat-Light"}
          fontSize={["32px", "56px"]}
          letterSpacing={[-1, -2]}
        >
          CONTACT US
        </Text>
        <Box
          display={"flex"}
          flexDir={["column", "row"]}
          borderWidth={"1px"}
          borderColor={"teal.300"}
          color={"white"}
          p={[5, 10]}
        >
          <Box textAlign={"left"} fontFamily={"Montserrat-Semibold"}>
            <Box m={5}>
              <Text
                fontSize={["18px", "28px"]}
                fontFamily={"Montserrat-Semibold"}
                letterSpacing={[-1, -2]}
              >
                GENERAL INQUIRIES
              </Text>
              <Text color={"whiteAlpha.700"}>
                polypixcreativecompany@gmail.com
              </Text>
            </Box>
            <Box m={5}>
              <Text
                fontSize={["18px", "28px"]}
                fontFamily={"Montserrat-Semibold"}
                letterSpacing={[-1, -2]}
              >
                CAREERS AT POLYPIX STUDIOS
              </Text>
              <Text color={"whiteAlpha.700"}>
                shekinabermejo.taktyl@gmail.com
              </Text>
            </Box>
            <Box fontFamily={"Montserrat-Semibold"} m={5}>
              <Text>POLYPIX STUDIOS</Text>
              <Text>WWW.POLYPIXSTUDIOS.COM</Text>
              <Text>PHILIPPINES</Text>
            </Box>
          </Box>
          <Box>
            <FormControl fontFamily={"Montserrat"}>
              <InputGroup display={"flex"} flexDir={"column"} mt={4}>
                <FormLabel
                  fontFamily={"Montserrat-Semibold"}
                  fontSize={["18px", "28px"]}
                  letterSpacing={[-1, -2]}
                >
                  NAME
                </FormLabel>
                <InputGroup gap={2}>
                  <Input
                    type={"text"}
                    placeholder={"FIRST NAME"}
                    color={"teal.200"}
                    textShadow={"0 0 1px teal"}
                    borderColor={"teal.300"}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <Input
                    type={"text"}
                    placeholder={"LAST NAME"}
                    color={"teal.200"}
                    textShadow={"0 0 1px teal"}
                    borderColor={"teal.300"}
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </InputGroup>
              </InputGroup>
              <InputGroup display={"flex"} flexDir={"column"} my={4}>
                <FormLabel
                  fontFamily={"Montserrat-Semibold"}
                  fontSize={["18px", "28px"]}
                  letterSpacing={[-1, -2]}
                >
                  E-MAIL
                </FormLabel>
                <Input
                  type={"text"}
                  placeholder={"E-MAIL"}
                  color={"teal.200"}
                  textShadow={"0 0 1px teal"}
                  borderColor={"teal.300"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup display={"flex"} flexDir={"column"}>
                <FormLabel
                  fontFamily={"Montserrat-Semibold"}
                  fontSize={["18px", "28px"]}
                  letterSpacing={[-1, -2]}
                >
                  RESUME
                </FormLabel>

                <Input
                  ref={hiddenFileInput}
                  onChange={handleFileSelect}
                  type={"file"}
                  display={"none"}
                />
                <InputGroup>
                  <InputLeftAddon
                    px={0}
                    children={
                      <Button
                        onClick={handleUpload}
                        backgroundColor={"teal.300"}
                      >
                        UPLOAD RESUME
                      </Button>
                    }
                  />
                  <Input
                    placeholder={"Upload PDF file"}
                    value={file?.name || ""}
                    isDisabled
                    _disabled={{ backgroundColor: "black" }}
                    color={"teal.200"}
                    textShadow={"0 0 1px teal"}
                    borderColor={"teal.300"}
                  />
                </InputGroup>
              </InputGroup>
              <Button
                backgroundColor={"teal.300"}
                width={"100%"}
                my={5}
                type={"submit"}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
