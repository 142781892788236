import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/navbar";
import ContactUs from "./ContactUs/contactUs";
import AssetPage from "./GameAssets/assetPage";
import Homepage from "./Home/homepage";
require("./fonts.css");

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Homepage />} />
          <Route path={"Assets"} element={<AssetPage />} />
          <Route path={"ContactUs"} element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
