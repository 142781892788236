import React, { useState, useEffect } from "react";
import { Box, Image, Text, Grid, GridItem } from "@chakra-ui/react";
import "react-multi-carousel/lib/styles.css";
import ViewAsset from "../modal/viewAsset";
import { useDispatch, useSelector } from "react-redux";
import { assets, assetSelector } from "../actions/assets";

const AssetPacks = () => {
  const dispatch = useDispatch();
  const asset = useSelector(assetSelector);
  const prop = asset?.data;
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  console.log(prop.data);
  // console.log(prop?.data[0].attributes.image.data[0].attributes.url);
  const handleShow = () => {
    setShow(true);
  };
  useEffect((data) => {
    dispatch(assets({ data: data }));
  }, []);
  return (
    <>
      <ViewAsset
        isModalVisible={show}
        handleView={() => setShow(false)}
        data={selectedValue ?? []}
      />
      <Box
        backgroundColor={"black"}
        display={"flex"}
        flexDir={"column"}
        alignContent={"center"}
        textAlign={"center"}
        py={10}
      >
        <Text
          color={"white"}
          fontFamily={"Montserrat-Light"}
          fontSize={["32px", "42px"]}
          letterSpacing={-2}
        >
          3D ASSETS
        </Text>
        <Grid
          templateColumns={["repeat(3, 1fr)", "repeat(4, 1fr)"]}
          py={10}
          gap={2}
        >
          {prop?.data?.map((item) => {
            return (
              <GridItem w={["100%", "100%"]}>
                <Box key={item.id}>
                  <Image
                    src={`https://api.polypixstudios.com${item.attributes.image.data[0].attributes.url}`}
                    onClick={() => {
                      handleShow();
                      setSelectedValue(item);
                    }}
                  />
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default AssetPacks;
