import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const baseUrl = "https://api.polypixstudios.com";

export const materials = createAsyncThunk(
  "users/Materials",
  async (thunkAPI) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/materials?populate=image,preview`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.status === 200) {
        return { data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const MaterialSlice = createSlice({
  name: "materials",
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      return state;
    },
  },

  extraReducers: {
    [materials.pending]: (state) => {
      state.loading = true;
    },
    [materials.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = false;
      state.error = false;
    },
    [materials.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { clearState } = MaterialSlice.actions;
export const materialSelector = (state) => state.materials;

// const materials = [
//   {
//     id: 1,
//     image: require("../assets/materials/brick.png"),
//     preview: require("../assets/maps/brick.png"),
//     name: "Brick",
//   },
//   {
//     id: 2,
//     image: require("../assets/materials/candy_corn.png"),
//     preview: require("../assets/maps/candy_corn.png"),
//     name: "Candy Corn",
//   },
//   {
//     id: 3,
//     image: require("../assets/materials/cement.png"),
//     preview: require("../assets/maps/cement.png"),
//     name: "Cement",
//   },
//   {
//     id: 4,
//     image: require("../assets/materials/concrete.png"),
//     preview: require("../assets/maps/concrete.png"),
//     name: "Concrete",
//   },
//   {
//     id: 5,
//     image: require("../assets/materials/dirt.png"),
//     preview: require("../assets/maps/dirt.png"),
//     name: "Dirt",
//   },
//   {
//     id: 6,
//     image: require("../assets/materials/filipino_window.png"),
//     preview: require("../assets/maps/filipino_window.png"),
//     name: "Filipino Window",
//   },
//   {
//     id: 7,
//     image: require("../assets/materials/grass_1.png"),
//     preview: require("../assets/maps/grass_1.png"),
//     name: "Grass 1",
//   },
//   {
//     id: 8,
//     image: require("../assets/materials/grass_2.png"),
//     preview: require("../assets/maps/grass_2.png"),
//     name: "Grass 2",
//   },
//   {
//     id: 9,
//     image: require("../assets/materials/moss_1.png"),
//     preview: require("../assets/maps/moss_1.png"),
//     name: "Moss 1",
//   },
//   {
//     id: 10,
//     image: require("../assets/materials/moss_2.png"),
//     preview: require("../assets/maps/moss_2.png"),
//     name: "Moss 2",
//   },
//   {
//     id: 11,
//     image: require("../assets/materials/party_bunting.png"),
//     preview: require("../assets/maps/party_bunting.png"),
//     name: "Party Bunting",
//   },
//   {
//     id: 12,
//     image: require("../assets/materials/rock_cement.png"),
//     preview: require("../assets/maps/rock_cement.png"),
//     name: "Rock Cement",
//   },
//   {
//     id: 13,
//     image: require("../assets/materials/stone_path.png"),
//     preview: require("../assets/maps/stone_path.png"),
//     name: "Stone Path",
//   },
//   {
//     id: 14,
//     image: require("../assets/materials/tintable_concrete.png"),
//     preview: require("../assets/maps/tintable_concrete.png"),
//     name: "Tintable Concrete",
//   },
//   {
//     id: 15,
//     image: require("../assets/materials/wooden_panel_1.png"),
//     preview: require("../assets/maps/wooden_panel.png"),
//     name: "Wooden Panel",
//   },
//   {
//     id: 16,
//     image: require("../assets/materials/wooden_plank.png"),
//     preview: require("../assets/maps/wooden_plank.png"),
//     name: "Wooden Planks",
//   },
//   {
//     id: 17,
//     image: require("../assets/materials/wooden_roof.png"),
//     preview: require("../assets/maps/wooden_roof.png"),
//     name: "Wooden Roof",
//   },
// ];

// export default materials;
