import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

import Logo from "../assets/logo.png";

const SectionA = (props) => {
  return (
    <Box
      backgroundColor={"black"}
      color={"white"}
      display={"flex"}
      justifyContent={"center"}
      flexDir={"column"}
      alignItems={"center"}
      textAlign={"center"}
      fontFamily={"montserrat"}
      py={20}
      px={10}
    >
      <Image src={Logo} w={["25%", "25%", "15%", "5%"]} />
      <Text
        fontFamily={"Montserrat-Bold"}
        letterSpacing={-2}
        fontSize={["23px", "23px", "23px", "28px"]}
      >
        {props?.content?.title}
      </Text>
      <Text
        w={["100%", "50%"]}
        fontSize={["15px", "15px", "15px", "20px"]}
        fontFamily={"Montserrat-Semibold"}
        color={"gray.400"}
        letterSpacing={-1}
      >
        {props?.content?.desc}
      </Text>
    </Box>
  );
};

export default SectionA;
