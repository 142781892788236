import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const SectionD = (props) => {
  const bg_url = `${props?.url}${props?.content?.image?.url}`;
  return (
    <Box
      height={"960px"}
      display={"flex"}
      flexDir={["column", "row"]}
      justifyContent={"stretch"}
    >
      <Box w={["100%", "50%"]} h={"100%"} backgroundColor={"black"}>
        <Image
          src={bg_url}
          w={"100%"}
          h={"100%"}
          objectFit={"cover"}
          //Added 100% height to remove blank spaces
        />
      </Box>
      <Box
        color={"white"}
        w={["100%", "50%"]}
        h={"100%"}
        display={"flex"}
        justifyContent={"stretch"}
        alignItems={"center"}
        backgroundColor={"black"}
      >
        <Box
          flexDir={"column"}
          display={"flex"}
          textAlign={["center", "right"]} //Added center alignment for mobile devies
          width={["100%"]}
          px={[0, 0, 20, 40, 40]}
        >
          <Text
            fontSize={["23px", "23px", "35px", "42px", "42px"]}
            letterSpacing={-2}
            fontFamily={"Montserrat-Bold"}
            width={["100%", "100%"]}
          >
            {props?.content?.title}
          </Text>
          <Text
            color={"gray.300"}
            fontFamily={"Montserrat-Light"}
            letterSpacing={-1}
            fontSize={["13px", "13px", "18px", "20px", "20px"]}
            textShadow={"0 0 1px white"}
            width={["100%", "100%"]}
          >
            {props?.content?.desc}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionD;
